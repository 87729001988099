import { getRoute } from 'next-type-safe-routes';

export const ROUTES = {
  HOME: getRoute({ route: '/' }),
  WHY_POWERLEAD: getRoute({ route: '/why-powerlead' }),
  get WHY_POWERLEAD_UNLIMITTED_DATA(): string {
    return `${this.WHY_POWERLEAD}#unlimitedData`;
  },
  get WHY_POWERLEAD_EXTENSION(): string {
    return `${this.WHY_POWERLEAD}#chromeExtension`;
  },
  PRICING: getRoute({ route: '/pricing-plans' }),
  OUR_DATA: getRoute({ route: '/our-data' }),
  DEMO: getRoute({ route: '/get-my-demo' }),
  ABOUT: getRoute({ route: '/about-us' }),
  CONTACT_US: getRoute({ route: '/contact-us' }),
  SALES_SOLUTIONS: getRoute({ route: '/sales-solutions' }),
  MARKETING_SOLUTIONS: getRoute({ route: '/marketing-solutions' }),
  RECRUITING_SOLUTIONS: getRoute({ route: '/recruiting-solutions' }),
  PRIVACY_CENTER: getRoute({ route: '/privacy-center' }),
  OPT_OUT: getRoute({ route: '/opt-out' }),
  PRIVACY_POLICY: getRoute({ route: '/privacy-policy' }),
  TERMS_OF_USE: getRoute({ route: '/terms' }),
  REQEST_ACCESS: getRoute({ route: '/request-my-info' }),
  SUCCESS_STORIES: getRoute({ route: '/success-stories' }),
};

const getDashboardRoute = (path: string): string => {
  return `${process.env.NEXT_PUBLIC_DASHBOARD_URL}${path}`;
};

export const EXTERNAL_ROUTES = {
  DASHBOARD: getDashboardRoute(''),
  DASHBOARD_SIGN_IN: getDashboardRoute('/sign-in'),
  DASHBOARD_SIGN_UP: getDashboardRoute('/signup'),
  DASHBOARD_PRICING: getDashboardRoute('/our-plans'),
  EXTENSION_MARKET:
    process.env.NEXT_PUBLIC_EXTENSION_URL ||
    'https://chrome.google.com/webstore/detail/powerlead-find-any-b2b-co/ijfmjempkpegmlhcacclfckeimbfgabp?hl=en&authuser=0',
  HELP_CENTER: 'https://help.powerlead.com/',
  SOCIAL: {
    LINKEDIN: 'https://www.linkedin.com/company/powerleadhq',
    FACEBOOK: 'https://www.facebook.com/powerleadhq',
    TWITTER: 'https://x.com/powerleadhq',
  },
  BLOG: 'https://www.powerlead.com/blog',
  HELP_CENTER_FAIR_USAGE_POLICY:
    'https://help.powerlead.com/en/articles/9921507-powerlead-fair-use-policy',
};
